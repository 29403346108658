:root {
  --primary-color: #00fdf0;
  --primary-color-shade: #7ba499;

  --secondary-color: #25bb9a;

  --dark-color: #212529;
  --dark-color-tint: #7a7c7f;
  --dark-color-shade: #142026;

  --background-color-light: #f5f5f5;
  --background-color-white: #fff;
}
